/* istanbul ignore file */
/**
 * @todo define module relative routing.
 * Global application should only define the base path to the module
 */
var routeConfig = {
    default: { path: '/' },
    // execution routes
    'list.incidents': {
        path: '/incident/list',
        name: { id: 'ehs_incidents_label_incident' }
    },
    'list.incidents.tag': {
        path: '/incident/list/:tagId',
        name: { id: 'ehs_incidents_label_incident' }
    },
    'incident.create': {
        path: '/incident/create',
        name: { id: 'ehs_incidents_label_incident' }
    },
    'incident.phase': {
        path: '/incident/:incidentId/:phaseType?/:formId?/:formType?'
    },
    'incident.group': {
        path: '/group/:incidentGroupId/:phaseType?'
    },
    'incident.group.phase': {
        path: '/group/:incidentGroupId/incident/:incidentId?/:phaseType?/:formId?/:formType?'
    },
    'incident.irf': {
        path: '/initial-report'
    },
    'incident.irf.login': {
        path: '/initial-report/login/:id?'
    },
    'incident.irf.report': {
        path: '/initial-report/create/:id'
    },
    'incident.irf.complete': {
        path: '/initial-report/complete/:id'
    },
    'incident.reporting.medical-record-export': {
        path: '/reporting/medical-record-export'
    },
    'incident.reporting.osha': {
        path: '/reporting/osha',
        name: { id: 'ehs_common_label_incidents' }
    },
    // <domain>/reporting/dashboard
    'reporting.dashboard': {
        path: '/dashboard',
        name: { id: 'dashboard' }
    }
};
export { routeConfig };
